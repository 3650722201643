.bulletList {
  list-style: none;
  padding-left: 15px;
}
.bulletListItem {
  list-style-type: none;
  position: relative;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;
  max-width: 636px;
  padding: 0 4px;
  word-wrap: break-word;
  float: left;
  clear: left;
}

.removedType {
  background: #ffd4d4;
  border-radius: 2px;
}
.addedType {
  background: #dff7ed;
  border-radius: 2px;
}

.bulletListItem::before {
  content: '';
  position: absolute;
  background: #4f4350;
  height: 6px;
  top: 22px;
  left: -12px;
  margin-top: -1em;
  width: 6px;
  border-radius: 50%;
}
.boldWeight {
  font-weight: 700;
  margin: 0 2px;
}
.oldTime {
  background: #fff6d4;
  border-radius: 2px;
  margin: 0 2px;
  padding: 1px;
  font-size: 14.5px;
}
.newTime {
  background: #dff7ed;
  border-radius: 2px;
  margin: 0 2px;
  padding: 1px;
  font-size: 14.5px;
}
